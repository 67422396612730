import React from "react"
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import keywords from "../../constants/keywords"
import CapabilitesCloudHero from "../../components/CapabilitesCloudHero"
import ImageTitleDescriptionBullets from "../../components/ImageTitleDescriptionBullets"
import ManagedCloudStorageImage from "../../assets/images/CloudServicesImages/A-hard-disk-for-a-raid-in-the-hands-of-a-man-at-a-data-storage-server-close-up.jpg"
import ManagedWordPressCloudHostingImage from "../../assets/images/CloudServicesImages/WordPress-Dev-Working-On-Computer.jpg"
import ManagedContentAccelerationServicesImage from "../../assets/images/CloudServicesImages/Person-Using-Phone-With-GPS.jpg"
import ManagedStreamingImage from "../../assets/images/CloudServicesImages/Streaming-Game-Play-Live.jpg"
import ManagedCybersecurityImage from "../../assets/images/CloudServicesImages/Person-Typing-In-Credit-Card-Information.jpg"
import ManagedCloudServicesImage from "../../assets/images/CloudServicesImages/Engineer-configuring-server-hardware.jpg"
import MaintenancePlansImage from "../../assets/images/CloudServicesImages/Hands-working-on-code-on-laptop.jpg"
import ComplianceAuditsImage from "../../assets/images/CloudServicesImages/Female_Leader_Holding_Paperwork_With_Group.jpg"

// const LoadableParallax = loadable(() => import('../components/parallax/homeParallax'))

const CloudServicesPage = () => {

  // let keywords = keywords.home.keywords
  // console.log(keywords.home.keywords)
  return (
    <>

      <Layout black={false}>

        <SEO
          title={"Cloud Services | Differnt Systems"}
          description={"Differnt delivers seamless Managed Cloud Services: scalable storage, website hosting, content acceleration, streaming, cybersecurity & maintenance."}
          customKeywords={keywords.home.keywords}
        />
        <div className="offcanvas-wrap">
          <CapabilitesCloudHero />

          <ImageTitleDescriptionBullets
            featureImage={ManagedWordPressCloudHostingImage}
            title="Managed WordPress Cloud Hosting"
            description="With over 43% of websites powered by WordPress, it's one of the most sought-after hosting services. However, its popularity has made it a prime target for cyber attacks. Without the right infrastructure and backups, WordPress sites can slow down, and plugin updates can cause issues. Our specialized architecture, coupled with a managed service agreement, ensures your business enjoys WordPress's benefits without the overhead."
            bullet1="Full Cloud Team Here to Support Your Growth"
            bullet2="Entirely Custom Solution, No Reselling of Hosting Services"
            bullet3="Advanced Backups, Security, Caching and CDN as Standard"
            subTitle="New"
            flip={true}
          />
          <ImageTitleDescriptionBullets
            featureImage={ManagedCybersecurityImage}
            title="Managed Cybersecurity"
            description="In early 2024, our daily efforts successfully mitigate over 140,000 cyber threats targeting our cloud infrastructures, ensuring uninterrupted client operations. Through a synergy of human expertise and advanced automation, our cybersecurity solutions leverage state-of-the-art tools and strategic partnerships with industry leaders. Collaborating closely with our clients, we prioritize the protection of their invaluable public, private, or hybrid cloud infrastructure."
            bullet1="Enhance Your Existing Infrastructure or Cloud Services"
            bullet2="Prevent Bots & Crawlers from Accessing Your Site"
            bullet3="Patch Discovered CVE Vulnerabilities in Real-Time"
            subTitle="Popular"
          />
          <ImageTitleDescriptionBullets
            featureImage={ComplianceAuditsImage}
            title="Compliance Audits"
            description="Our comprehensive audit solutions leverage veteran CIO expertise and advanced automated frameworks to ensure regulatory compliance. We support audits for ISO 27001, HIPAA, SOC 2, GDPR, PCI DSS, NIST 800-53, GLBA, and more. Through industry-leading tools and strategic partnerships, we help organizations meet and exceed evolving regulatory standards."
            bullet1="Enhance Your Existing Compliance Processes"
            bullet2="Streamline Audit Preparation and Execution"
            bullet3="Identify and Address Compliance Gaps in Real-Time"
            subTitle=""
            flip={true}
          />
          <ImageTitleDescriptionBullets
            featureImage={ManagedCloudStorageImage}
            title="Managed Cloud Storage"
            description="Near-infinite storage resources are available globally, with redundancy across multiple data centers and encryption at rest, ensuring the protection of your data even during catastrophes. Access your data through a user-friendly portal interface, new/existing software applications, or other flexible access options. Share resources effortlessly through a unique link or maintain complete privacy. With flexibility as its core strength, your data management reaches new heights."
            bullet1="Access Your Data with Ease 24/7, Remotely"
            bullet2="Predictable Cost Forecasting to Always Remain In Budget"
            bullet3="Store Files Indefinitely with Redundant Backups"
            subTitle=""
          />
          <ImageTitleDescriptionBullets
            featureImage={ManagedContentAccelerationServicesImage}
            title="Managed Content Acceleration Services"
            description="Standard network infrastructure often struggles to keep pace with growing demand. However, by leveraging a purpose-built Content Delivery Network (CDN), businesses can ensure resilience and uninterrupted performance, even during sudden spikes in traffic or unexpected surges in workload. Whether it's streaming media, processing transactions, or delivering web content, our Content Acceleration Services enable seamless scaling."
            bullet1="Absorb Spikes in Traffic Without Additional Server Power"
            bullet2="Create Digital Waiting Room To Ease Demand"
            bullet3="Deliver Content and Resources Even During Server Outages"
            subTitle="Trending"
            flip={true}
          />
          <ImageTitleDescriptionBullets
            featureImage={ManagedStreamingImage}
            title="Managed Streaming"
            description="Deliver a buffer-free adaptive streaming experience across a global infrastructure spanning multiple CDNs and storage systems, catering to live, simulated live, or VOD content. Standard features encompass transcoding, transmuxification, redundancy, geo-restrictions, DRM, and storage encryption. Craft a reliable and secure system devoid of management headaches."
            bullet1="Lossless Audio & 8K HDR Video Playback Available"
            bullet2="Serve Content Anywhere with Approved China CDNs"
            bullet3="Secure Content with Player-Level Code Shielding"
            subTitle=""
          />
          <ImageTitleDescriptionBullets
            featureImage={ManagedCloudServicesImage}
            title="Managed Cloud Services"
            description="Unlock the cloud's potential hassle-free. While the cloud presents limitless opportunities, navigating its procurement, maintenance, management, and security can be overwhelming. Let our team of experts alleviate the burden. We'll design, implement, and maintain your systems across public, private, or hybrid cloud environments, ensuring optimal performance, scalability, and cost-efficiency."
            bullet1="Integrate Our Team Into Your Operations with Ease"
            bullet2="Completely Vendor-Agnostic Approach to Cloud"
            bullet3="Available On-Site or Remote Technicians"
            subTitle=""
            flip={true}
          />
          <ImageTitleDescriptionBullets
            featureImage={MaintenancePlansImage}
            title="Maintenance Plans"
            description="Internally managing and maintaining your app, website, or web application diverts essential resources from the core mission of your organization. We offer a flexible approach, allowing you to bring your current system(s) or opt for continued maintenance of your newly developed different system. We make it effortless to build and sustain cutting-edge technology."
            bullet1="Tailor a Plan to Meet Your Requirements"
            bullet2="Reduce Internal Load While Increasing Results"
            bullet3="Maintain Security, Stability & Compliance"
            subTitle=""
            flip={true}
          />
          {/* <LoadableParallax /> */}
        </div >
      </Layout >

    </>
  )
}

export default CloudServicesPage